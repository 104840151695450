define("discourse/plugins/discourse-solved/discourse/templates/connectors/topic-navigation/no-answer", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.show}}
    <TopicNavigationPopup
      @popupId="solved-notice"
      @dismissDuration={{this.oneWeek}}
    >
      <h3>{{i18n "solved.no_answer.title"}}</h3>
      <p>{{i18n "solved.no_answer.description"}}</p>
    </TopicNavigationPopup>
  {{/if}}
  */
  {
    "id": "GZtl3ncW",
    "block": "[[[41,[30,0,[\"show\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@popupId\",\"@dismissDuration\"],[\"solved-notice\",[30,0,[\"oneWeek\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[10,\"h3\"],[12],[1,[28,[35,2],[\"solved.no_answer.title\"],null]],[13],[1,\"\\n    \"],[10,2],[12],[1,[28,[35,2],[\"solved.no_answer.description\"],null]],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"topic-navigation-popup\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-solved/discourse/templates/connectors/topic-navigation/no-answer.hbs",
    "isStrictMode": false
  });
});