define("discourse/plugins/discourse-solved/discourse/templates/connectors/topic-after-cooked/solved-panel", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.topic.accepted_answer}}
    <p class="solved">
      {{html-safe this.topic.acceptedAnswerHtml}}
    </p>
  {{/if}}
  */
  {
    "id": "3EjRoLCM",
    "block": "[[[41,[30,0,[\"topic\",\"accepted_answer\"]],[[[1,\"  \"],[10,2],[14,0,\"solved\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[[30,0,[\"topic\",\"acceptedAnswerHtml\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"html-safe\"]]",
    "moduleName": "discourse/plugins/discourse-solved/discourse/templates/connectors/topic-after-cooked/solved-panel.hbs",
    "isStrictMode": false
  });
});