define("discourse/plugins/discourse-solved/discourse/templates/connectors/user-card-metadata/accepted-answers", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.user.accepted_answers}}
    <span class="desc">{{i18n "solutions"}}</span>
    <span>{{this.user.accepted_answers}}</span>
  {{/if}}
  */
  {
    "id": "WCD7dsMb",
    "block": "[[[41,[30,0,[\"user\",\"accepted_answers\"]],[[[1,\"  \"],[10,1],[14,0,\"desc\"],[12],[1,[28,[35,1],[\"solutions\"],null]],[13],[1,\"\\n  \"],[10,1],[12],[1,[30,0,[\"user\",\"accepted_answers\"]]],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-solved/discourse/templates/connectors/user-card-metadata/accepted-answers.hbs",
    "isStrictMode": false
  });
});